.text:hover {
  cursor: pointer;
  background-color: azure;
  transform: scale(1.01);
}

.active {
  font-weight: bold;
  color: gray;
}
