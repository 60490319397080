.section {
  margin-top: 4%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 35%;
}

.title {
  font-size: 22px;
  margin-bottom: -1%;
}

.formInput {
  height: 30px;
  border: none;
  border-bottom: 1px solid gray;
  padding-bottom: 3%;
  padding-left: 2%;
  width: 70%;
  outline: none;
  font-size: 14px;
}

.buttonGroup {
  width: 10%;
  border: 1px solid gray;
  height: 37px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 14px;
  margin-top: 5%;
}

.buttonGroup:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.mainBigContainer {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.createGroup {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex: 0.8;
}