.dateButton {
  margin-bottom: 2%;
  font-weight: bold;
}

.dateButton:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.deleteButton:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.myJournalContainer {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.myJournals {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  flex: 0.8;
  margin-bottom: 5%;
}
